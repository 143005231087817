.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.crm-mobile {
  width: 100vw;
}
.crm-mobile img {
  width: 100%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}
.crm-mobile .bottom {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3.5416666666666665vw;
  width: 100%;
  background-image: url(https://mktv-in-cdn.mockuai.com/16363504866298437.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.crm-mobile .bottom .btn {
  width: 9.583333333333334vw;
  height: 2.34375vw;
  margin-left: 54.479166666666664vw;
  margin-top: 1.3020833333333335vw;
  margin-bottom: 1.5104166666666667vw;
  cursor: pointer;
}
.crm-mobile .footer {
  text-align: right;
}
.crm-mobile .close,
.crm-mobile .primary {
  font-size: 0.7291666666666666vw;
  border-radius: 0.20833333333333334vw;
  line-height: 0.7291666666666666vw;
  padding: 0.5208333333333333vw;
  font-family: Helvetica;
  border: 1px #ccc solid;
  cursor: pointer;
}
.crm-mobile .close {
  margin-right: 0.5208333333333333vw;
  background: #fff;
  color: #475669;
}
.crm-mobile .primary {
  background: #306eed;
  color: #fff;
}
.crm-mobile .dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.crm-mobile .dialog .box {
  width: 20.3125vw;
  height: 12.5vw;
  border-radius: 0.10416666666666667vw;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #d3dce6;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  margin-top: 10.416666666666668vw;
}
.crm-mobile .dialog .box .title {
  font-size: 0.8333333333333334vw;
  padding: 1.0416666666666665vw;
  font-family: Helvetica;
  color: #1f2d3d;
  line-height: 16px;
}
.crm-mobile .dialog .box .main {
  padding: 1.0416666666666665vw;
}
.crm-mobile .dialog .box .main .el-form-item {
  display: flex;
  width: 18.229166666666664vw;
}
.crm-mobile .dialog .box .main .el-form-item /deep/ .el-input__inner {
  height: 1.875vw;
  font-size: 0.7291666666666666vw;
}
.crm-mobile .dialog .box .main /deep/ .el-form-item__label {
  font-size: 0.7291666666666666vw;
  width: 4.427083333333334vw;
  padding-right: 0;
  text-align: left;
}
.crm-mobile .dialog .box .main /deep/ .el-form-item__label::before {
  display: none;
}
.crm-mobile .dialog .box .main /deep/ .el-form-item__content {
  flex: 1;
}
.crm-mobile .dialog .box .footer {
  position: absolute;
  right: 1.0416666666666665vw;
  bottom: 1.0416666666666665vw;
}
.crm-mobile .message {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.crm-mobile .message .box {
  width: 20.3125vw;
  height: 10.520833333333334vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  border: 1px solid #d3dce6;
  margin-top: 10.416666666666668vw;
}
.crm-mobile .message .box .success,
.crm-mobile .message .box .error {
  display: flex;
  align-items: center;
}
.crm-mobile .message .box .success img,
.crm-mobile .message .box .error img {
  display: block;
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
  font-size: 0.7291666666666666vw;
  padding-left: 1.0416666666666665vw;
  padding-right: 1.0416666666666665vw;
}
.crm-mobile .message .box .success .text,
.crm-mobile .message .box .error .text {
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #475669;
}
.crm-mobile .message .box .msg_btn {
  width: 3.3333333333333335vw;
  height: 1.875vw;
  font-size: 0.7291666666666666vw;
  border-radius: 0.20833333333333334vw;
  background: #306eed;
  border: none;
  position: absolute;
  color: #fff;
  right: 1.0416666666666665vw;
  bottom: 0.78125vw;
  cursor: pointer;
}
